import React from 'react';
import GeneralHeader from "../compounds/GeneralHeader";
import GeneralLinkings from "../compounds/GeneralLinkings";
import Projects from "../data/projects.json";
import ProjectPreview from "../compounds/ProjectPreview";

function Portfolio() {
    return (
      <div>
        <GeneralHeader/>
        <h3>Projects</h3>
        <div className="portfolio">
          {
            Projects.map((project, index) => {
                return <ProjectPreview key={project.name} image={project.image} title={project.name} detailsUrl={project.links[0].url}/>
            })
          }
        </div>
        <GeneralLinkings/>
      </div>
    );
  }

export default Portfolio;
