import React from 'react';
import ReactDOM from 'react-dom/client';
import './Home.css';
import Home from './pages/Home';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import Portfolio from "./pages/Portfolio";
import Imprint from "./pages/Imprint";
import Contact from "./pages/Contact";
import DeleteAccountPage from "./compounds/DeleteAccountPage";

const router = createHashRouter([
    {
        path: `contact`,
        element: <Contact/>,
    },
    {
        path: `projects`,
        element: <Portfolio/>,
    },
    {
        path: "",
        element: <Home/>,
    },
    {
        path: `imprint`,
        element: <Imprint/>,
    },
    {
        path: `stay-connected-account-deletion`,
        element: <DeleteAccountPage/>,
    },
    {
        path: `*`,
        element: <ErrorPage/>,
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <div  className="App">
          <RouterProvider router={router} fallbackElement={<ErrorPage/>}/>
      </div>
  </React.StrictMode>
);
