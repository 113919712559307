import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

function GeneralHeader() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname === "/")
            document.title = "Felix Beinßen - About";
        else if(location.pathname === "/projects")
            document.title = "Felix Beinßen - Projects";
        else if(location.pathname === "/skills")
            document.title = "Felix Beinßen - Skills";
        else
            document.title = "Felix Beinßen";

        //preload all images in ./technologies
        let images = document.createElement("div");
        images.style.display = "none";
        images.innerHTML = "";
        for(let i = 0; i < document.getElementsByClassName("Technology").length; i++) {
            images.innerHTML += `<img src="./technologies/${document.getElementsByClassName("Technology")[i].innerHTML}.svg" alt="${document.getElementsByClassName("Technology")[i].innerHTML}"/>`;
        }
        document.body.appendChild(images);
    }, [location]);

    return (
        <div>
            <img src={"/Felixx.svg"} alt="Logo"/>
            <h1>Felix Beinßen</h1>
            <h3>Software Developer, Game Designer, Writer</h3>
            <div className={"navigation"}>
                <button disabled={location.pathname === "/"} onClick={() => {
                    navigate("/");
                }}>ABOUT</button>
                <button disabled={location.pathname === "/projects"} onClick={() => {
                    navigate("/projects");
                }}>PROJECTS</button>
                <button disabled={location.pathname === "/experience"} onClick={() => {
                    window.open("https://www.linkedin.com/in/felix-bein%C3%9Fen-18b2521b7/", "_blank");
                }}>EXPERIENCE</button>
            </div>
        </div>
    );
}

export default GeneralHeader;
