// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: 'AIzaSyDNvvG6EmGQDOreM3xN6E_X8TL5ZOypzkk',
    authDomain: 'stayconnected-ce0fb.firebaseapp.com',
    databaseURL: 'https://stayconnected-ce0fb.firebaseio.com',
    projectId: 'stayconnected-ce0fb',
    storageBucket: 'stayconnected-ce0fb.appspot.com',
    messagingSenderId: '92839050330',
    appId: '1:92839050330:android:109ddb7515268a67d82c63',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
