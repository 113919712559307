import GeneralLinkings from "../compounds/GeneralLinkings";
import GeneralHeader from "../compounds/GeneralHeader";

function Contact() {
    return (
        <div>
            <GeneralHeader/>
            <h3>Mail</h3>
            <p>For any questions, please contact me<a href="mailto:info@felixbeinssen.net">here</a>.</p>
            <h3>Meet me</h3>
            <p>For a personal meeting, please book an appointment<a href="https://app.reclaim.ai/m/felix-beinssen">here</a>.</p>
            <br/>
            <GeneralLinkings/>
        </div>
    );
}

export default Contact;
