import React from 'react';
import { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, deleteUser } from 'firebase/auth';

const DeleteAccountPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [currentUser, setCurrentUser] = useState(auth.currentUser);

    auth.onAuthStateChanged((user) => {
        setCurrentUser(user);
    });

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            alert('Login erfolgreich!');
        } catch (error) {
            alert('Fehler beim Login: ' + error);
        }
    };

    const handleDeleteAccount = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                await deleteUser(user);
                alert('Account erfolgreich gelöscht!');
            } catch (error) {
                alert('Fehler beim Löschen des Accounts: ' + error);
            }
        } else {
            alert('Kein Benutzer eingeloggt.');
        }
    };

    return (
        <div>
            <h1>StayConnected</h1>
            <h2>Account löschen</h2>
            {
                currentUser ?
                    <div>
                        <p>Angemeldet als: {currentUser.email}</p>
                        <button onClick={handleDeleteAccount}>Account löschen</button>
                        <button onClick={() => auth.signOut()}>Abmelden</button>
                    </div>
                    :
                    <div>
                        <p>Bitte melde dich an, um deinen Account zu löschen.</p>
                        <input type="text" placeholder="E-Mail" onChange={(event) => setEmail(event.target.value)}/>
                        <input type="password" placeholder="Passwort" onChange={(event) => setPassword(event.target.value)}/>
                        <button onClick={handleLogin}>Anmelden</button>
                    </div>
            }
        </div>
    );
};

export default DeleteAccountPage;
