import { useLocation } from "react-router-dom";
import GeneralHeader from "../compounds/GeneralHeader";

function ErrorPage() {
    const location = useLocation();

    return (
        <div>
            <GeneralHeader/>
            <h1>404</h1>
            <h3>Page not found</h3>
            <p>
                The page {location.pathname} does not exist.
            </p>
            <button className={"backButton"} onClick={() => {
                window.history.back();
            }}>Go back</button>
        </div>
    );
}

export default ErrorPage;