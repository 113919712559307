import React from 'react';
import {useNavigate} from "react-router-dom";

function ProjectPreview({image, title, detailsUrl}: { image: string, title: string, detailsUrl: string }) {
    const navigate = useNavigate();
    return (
        <div  onClick={
            () => {
                if(!detailsUrl.startsWith("/"))
                    window.open(detailsUrl, "_blank");
                else
                    navigate(detailsUrl)
            }
        } className={"ProjectPreview"}>
            <img src={image} alt={title}/>
            <h3>{title}</h3>
        </div>
    );
}

export default ProjectPreview;
