import GeneralLinkings from "../compounds/GeneralLinkings";
import GeneralHeader from "../compounds/GeneralHeader";
import PersonalDescription from "../compounds/PersonalDescription";

function Home() {
    return (
        <div>
            <GeneralHeader/>
            <PersonalDescription/>
            <GeneralLinkings/>
        </div>
    );
}

export default Home;
