import React, { useEffect, useState } from 'react';
import Projects from "../data/projects.json";
import { useNavigate } from 'react-router-dom';

function PersonalDescription() {
    const [sortedSkills, setSortedSkills] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const skillsMap = Projects.flatMap(p => p.technologies)
            .reduce((acc, skill) => {
                acc[skill] = (acc[skill] || 0) + 1;
                return acc;
            }, {});

        const skillsArray = Object.entries(skillsMap)
            .filter(([skill]) => skill !== "Photoshop" && skill !== "Blender")
            .sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0]));

        setSortedSkills(skillsArray.map(([skill]) => skill));
    }, []);

    return (
        <div className={"personalDescription"}>
            <h3>About Me</h3>
            <p>
                I'm a Programmer from Germany, currently working for the prestigious game studio CipSoft. With a foundation in applied computer science from Anhalt University, I've enriched my academic journey with four prestigious scholarships like the Deutschland Stipendium and a semester abroad at Trinity College Dublin.
                <br />
                Beyond my studies, my contributions extend to academic and social spheres, including participation on the examination board and research assistance in web development and programming modules.
                <br />
                I've also worked for Pixelgame Studios and PROCLANE Integration.
            </p>
            <h3>My Skills</h3>
            <div className={"skills"}>
                {sortedSkills.map((skill, index) => (
                    <div key={index} className="SkillCard">
                        <img src={`/technologies/${skill.toLowerCase()}.png`} alt={skill} />
                        <p>{skill}</p>
                    </div>
                ))}
            </div>
            <div className={"meeting"}>
                <h3>Let's meet</h3>
                <p>
                    I'm always open to new opportunities and collaborations. If you're interested in working with me, feel free to reach out.
                </p>
                <button onClick={() => navigate("/contact")}>
                    Contact Me
                </button>
            </div>
        </div>
    );
}

export default PersonalDescription;
