import React from 'react';
import {AiFillLinkedin} from "@react-icons/all-files/ai/AiFillLinkedin";
import {AiFillGithub} from "@react-icons/all-files/ai/AiFillGithub";
import {FaItchIo} from "@react-icons/all-files/fa/FaItchIo";
import {useNavigate} from "react-router-dom";

function GeneralLinkings() {
    const navigate = useNavigate();
    return (
        <div>
            <div className={"footer"}>
                <div className={"socials"}>
                    <a href={"https://www.linkedin.com/in/felix-bein%C3%9Fen-18b2521b7/"} title={"LinkedIn"} target={"_blank"} rel="noreferrer">
                        <AiFillLinkedin size={"100%"}/>
                    </a>
                    <a href={"https://github.com/chafficui"} title={"GitHub"} target={"_blank"} rel="noreferrer">
                        <AiFillGithub size={"100%"}/>
                    </a>
                    <a href={"https://felixxbe.itch.io/"} title={"itch.io"} target={"_blank"} rel="noreferrer">
                        <FaItchIo size={"100%"}/>
                    </a>
                </div>
                <div >
                    <a onClick={() => {
                        navigate("/imprint");
                    }}>Imprint</a>
                    <a onClick={() => {
                        navigate("/contact");
                    }}>Contact</a>
                </div>
            </div>
        </div>
    );
}

export default GeneralLinkings;
