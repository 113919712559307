import React from 'react';
import GeneralHeader from "../compounds/GeneralHeader";
import GeneralLinkings from "../compounds/GeneralLinkings";

function Imprint() {
    return (
        <div>
            <GeneralHeader/>
            <h3>Imprint</h3>
            <div className={"imprint"}>
                <p>Information in accordance with Section 5 TMG</p>
                <p>Felix Beinßen</p>
                <p>Dr.-Daßler-Str. 46</p>
                <p>91074 Herzogenaurach</p>
                <p>Represented by:</p>
                <p>Felix Beinßen</p>
                <h4>Contact:</h4>
                <p>Telephone: </p>
                <p>E-Mail:
                    <a href={"mailto:info@felixbeinssen.net"}>
                        mail
                    </a>
                </p>
                <p>Disclaimer:</p>
                <p>Liability for Links</p>
                <p>Our offer contains links to external websites of third parties, on whose contents we have no
                    influence. Therefore, we cannot assume any liability for these external contents. For the contents
                    of the linked pages, always the respective provider or operator of the pages is responsible. The
                    linked pages were checked for possible violations of law at the time of linking. Illegal contents
                    were not recognizable at the time of linking. A permanent monitoring of the content of the linked
                    pages is however not reasonable without concrete evidence of a violation of law. Upon notification
                    of violations, we will remove such links immediately.</p>
            </div>
            <GeneralLinkings/>
        </div>
    );
}

export default Imprint;